
// Vue
import {Options, Vue} from "vue-class-component";

// Store
import store from "@/store/index";

// Vendors
import moment from "moment";

// Models
import {CoachPlayersProfileTipsListIndex} from "@/models/app/coach/players/profile/tips/list/index";
import {ResponseIndex} from "@/models/response";

// Components
import {
  ElRow,
  ElCol,
} from "element-plus";
import CoachPlayersProfileTipsListFormCreateIndexVue from "@/views/app/coach/players/profile/tips/list/form/create.vue";
import CoachPlayersProfileTipsListFormUpdateIndexVue from "@/views/app/coach/players/profile/tips/list/form/update.vue";
import CoachPlayersProfileTipsListFormRemoveIndexVue from "@/views/app/coach/players/profile/tips/list/form/remove.vue";

// Services
import {getRequest} from "@/services/api/request";

@Options({
  components: {
    ElRow,
    ElCol,
    CoachPlayersProfileTipsListFormCreateIndexVue,
    CoachPlayersProfileTipsListFormUpdateIndexVue,
    CoachPlayersProfileTipsListFormRemoveIndexVue,
  },
})
export default class CoachPlayersProfileTrainingsListIndexVue extends Vue {
  isLoading: boolean | null = false;

  formData: any = {
    filter: {
      user_id: "*",
    },
    organization_id: store.getters.getOrganizationMember.organization_id,
  };

  moment: any = null;

  tips: Array<CoachPlayersProfileTipsListIndex> = [];

  async getTips(): Promise<void> {
    this.isLoading = true;
    await getRequest({
      uri: `/common/tips`,
      formData: this.formData,
      isProtected: true,
    }).then((r: ResponseIndex) => {
      if (r.data) {
        this.tips = r.data;
      }
    });
    this.isLoading = false;
  }

  async created(): Promise<void> {
    this.moment = moment;
    this.formData.filter.user_id = this.$route.params.id;
    await this.getTips();
  }
}
