
// Vue
import {Options, Vue} from "vue-class-component";

// Components
import {
  ElRow,
  ElCol,
} from "element-plus";
import CoachNavigationVue from "@/components/coach/navigation.vue";
import CoachPlayersProfileNavigationVue from "@/components/coach/players/profile/navigation.vue";
import CoachPlayersProfileTipsListIndexVue from "@/views/app/coach/players/profile/tips/list/index.vue";

@Options({
  components: {
    ElRow,
    ElCol,
    CoachNavigationVue,
    CoachPlayersProfileNavigationVue,
    CoachPlayersProfileTipsListIndexVue
  },
})
export default class CoachPlayersProfileTipsIndexVue extends Vue {
}
